import { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormInput } from 'app/components/fields/form-input';
import { AddButtonWrapper, ControlsGroup, StyledPanel } from './visitor-details.styles';
import { DeleteVisitor } from './delete-visitor/delete-visitor';
import { InternationalPhoneInput } from 'app/components/fields/international-phone-input';
import { SearchVisitor } from './search-visitor/search-visitor';
import { useSelectedVisitorData } from './use-selected-visitor-data.hook';
import { showVisitorsTypeAheadSelector } from 'app/store/config/selectors';
import { useFieldMetadata } from 'app/store/ui-metadata/hooks';
import { useUIMetadataFieldRequirements } from 'app/shared/hooks/use-ui-metadata-field-requirements/use-ui-metadata-field-requirements.hook';
import { hasManageCreateVipVisitPermissionSelector } from 'app/store/user-permissions/selectors';
import { MoreOptions } from './more-options/more-options';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { useIntl } from 'react-intl';
import { ResetVisitor } from 'app/components/shared/visitor-details/reset-visitor';
import { useField } from 'formik';

interface VisitorDetailsProps {
  isDeleteAvailable: boolean;
  canEditVisitorDetails: boolean;
  canChangeVisitVisibility?: boolean;
  fieldNamePrefix: string;
  handleVisitorDelete?: VoidFunction;
  handleResetVisitor?: VoidFunction;
  showMoreOptions?: boolean;
  isAddButtonAvailable?: boolean;
  onAddButtonClick?: VoidFunction;
  addButtonLoading?: boolean;
}

export const VisitorDetails: FC<VisitorDetailsProps> = ({
  isDeleteAvailable,
  canEditVisitorDetails,
  canChangeVisitVisibility,
  fieldNamePrefix,
  handleVisitorDelete,
  handleResetVisitor,
  showMoreOptions = true,
  isAddButtonAvailable = false,
  onAddButtonClick,
  addButtonLoading,
}) => {
  const selectedVisitorIsUser = useSelectedVisitorData(fieldNamePrefix);
  const isInputsDisabled = selectedVisitorIsUser || !canEditVisitorDetails;
  const showVisitorsTypeAheadLD = useSelector(showVisitorsTypeAheadSelector);
  const showMoreItems = useSelector(hasManageCreateVipVisitPermissionSelector) && showMoreOptions;
  const visitorTypeaheadEnabled = useFieldMetadata('visitors_typeahead_supported');
  const showVisitorsTypeAhead = showVisitorsTypeAheadLD && visitorTypeaheadEnabled;
  const { hidden: phoneHidden, required: phoneRequired } = useUIMetadataFieldRequirements('visitor_phone');
  const { hidden: emailHidden, required: emailRequired } = useUIMetadataFieldRequirements('visitor_email');
  const [{ value: waiveValue }] = useField(`${fieldNamePrefix}.contact_info_waived`);
  const isEmailRequired = emailRequired && !waiveValue;
  const isPhoneRequired = phoneRequired && !waiveValue;
  const { formatMessage } = useIntl();

  return (
    <StyledPanel data-testid="visitor-details-component" showMoreOptions={showMoreOptions}>
      {showMoreItems && (
        <MoreOptions fieldNamePrefix={fieldNamePrefix} canChangeVisitVisibility={canChangeVisitVisibility} />
      )}
      {!emailHidden && (showVisitorsTypeAhead && !isInputsDisabled ? (
        <SearchVisitor fieldNamePrefix={fieldNamePrefix} required={isEmailRequired} />
      ) : (
        <FormInput
          fieldName={`${fieldNamePrefix}.email`}
          translationKey="visitor.email"
          required={isEmailRequired}
          disabled={isInputsDisabled}
        />
      ))}
      <FormInput
        fieldName={`${fieldNamePrefix}.firstName`}
        translationKey="visitor.firstName"
        disabled={isInputsDisabled}
        required
      />
      <FormInput
        fieldName={`${fieldNamePrefix}.lastName`}
        translationKey="visitor.lastName"
        disabled={isInputsDisabled}
        required
      />
      {!phoneHidden && (
        <InternationalPhoneInput
          fieldName={`${fieldNamePrefix}.phone`}
          translationKey="visitor.phone"
          disabled={isInputsDisabled}
          required={isPhoneRequired}
        />
      )}
      <ControlsGroup>
        {!!handleResetVisitor && <ResetVisitor onResetVisitor={handleResetVisitor} />}
        {isDeleteAvailable && handleVisitorDelete && <DeleteVisitor handleVisitorDelete={handleVisitorDelete} />}
      </ControlsGroup>
      {isAddButtonAvailable && onAddButtonClick && (
        <AddButtonWrapper>
          <Button
            onClick={onAddButtonClick}
            text={formatMessage({ id: 'buttons.add' })}
            variant="Primary"
            loading={addButtonLoading}
            data-testid="add-visitor-button"
          />
        </AddButtonWrapper>
      )}
    </StyledPanel>
  );
};
