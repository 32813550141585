import { VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';

export enum VisitorType {
  USER = 'user',
  EXTERNAL = 'external',
}

export interface Visitor {
  uuid: string;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  company_uuid?: string;
  company_name?: string;
  type: VisitorType;
  visibility: VisibilityEnum;
  contact_info_waived?: boolean;
}

export interface VisitorState {
  visitor: Visitor | null;
  loading: boolean;
  error: Error | null;
}

export type VisitorStateByUuid = Record<string, VisitorState>;

export interface FetchVisitorParams {
  uuid: string;
}

export interface FetchVisitorSuccessDto {
  params: FetchVisitorParams;
  response: Visitor;
}

export interface FetchVisitorErrorDto {
  params: FetchVisitorParams;
  error: Error;
}
